<template>
  <div>
    <div v-for="post in postsStore.posts" :key="post.id" class="mb-20">
      <NuxtLink :to="`/${post.category.slug}/${post.slug}`" class="font-bold text-xl">{{ post.title }}</NuxtLink>
      
      <p class="text-sm text-slate-500 font-medium tracking-tighter">{{ post.category.name }} · {{ post.user.username }} · {{ post.created_at }}</p>
        <!-- eslint-disable-next-line -->
      <p class="text-sm" v-html="post.truncated_content" />
      <p v-if="post.media_path">
        <NuxtImg :src="post.media_path" class="rounded w-200" loading="lazy" format="webp" :modifiers="{ roundCorner: '10:10' }" />
      </p>
      <div>
        <ol class="inline-flex mt-8 space-x-2">

           <!-- LIKES -->
          <li v-if="isAuthenticated" class="bg-slate-100 rounded-full px-4 flex items-center hover:bg-slate-200">
            <UButton
              icon="i-material-symbols-light-thumb-up-outline"
              :ui="{ rounded: 'rounded-full' }"
              size="lg"
              padding="lg"
              color="black"
              variant="ghost"
              @click="post.likes_count++"
            > {{ post.likes_count }}
            </UButton>
          </li>


          <!-- COMMENTS -->
          <li v-if="isAuthenticated" class="bg-slate-100 rounded-full px-4 flex items-center hover:bg-slate-200">
            <UButton
              :to="`/${post.category.slug}/${post.slug}`"
              icon="i-material-symbols-light-mode-comment-outline"
              :ui="{ rounded: 'rounded-full' }"
              size="lg"
              padding="lg"
              color="black"
              variant="ghost"
            /> <a class="text-sm mr-2 ">{{ post.comments_count}}</a>
          </li>



          <!--------------------
            USER STUFF
          ---------------------->

          <!-- BOOKMARK -->
          <span class="flex rounded-full bg-slate-200">
          <li v-if="isAuthenticated" class="px-4 flex items-center">
            <UButton
              icon="i-material-symbols-light-bookmark-add-outline"
              :ui="{ rounded: 'rounded-full' }"
              size="lg"
              padding="lg"
              color="black"
              variant="ghost"
            />
          </li>


          <!-- EDIT -->
          <li v-if="isAuthenticated && post.user_id == user.id" class="px-4 flex items-center">
            <UButton
            icon="i-material-symbols-light-contract-edit-outline"
            :ui="{ rounded: 'rounded-full' }"
            size="lg"
            padding="lg"
            color="black"
            variant="ghost"
            @click="editPost(post.id)" />
          </li>


          <!-- DELETE -->
          <li v-if="isAuthenticated && post.user_id == user.id" class="px-4 flex items-center">
            <UButton
            icon="i-material-symbols-light-delete-outline"
            :ui="{ rounded: 'rounded-full' }"
            size="lg"
            padding="lg"
            color="black"
            variant="ghost"
            @click="deletePost(post.id)" />
          </li>
        </span>
        </ol>
      </div>
    </div>
    <div v-if="postsStore.loading" class="loading">
      <UButton color="primary" loading>Cargando...</UButton>
    </div>
  </div>
</template>

<script setup lang="ts">
const { isAuthenticated } = useSanctumAuth();
const user = useSanctumUser<User>();
const postsStore = usePostsStore()
//const deleteOpen = ref(false)
const logoutOpen = ref(false)
const loginOpen = ref(false)
const count = ref(0)

const handleScroll = () => {
  const { scrollTop, scrollHeight, clientHeight } = document.documentElement
  if (scrollTop + clientHeight >= scrollHeight - 10) {
    postsStore.fetchPosts()
  }
}

onMounted(() => {
  postsStore.fetchPosts()
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})

///
const client = useSanctumClient()
//fetch data from API with "useAsyncData"

const deletePost = async (id: number) => {
    //delete data with API
    const config = useRuntimeConfig();
    client(`${config.public.sanctum.baseUrl}/api/posts/${id}`, {
      method: 'DELETE',
    });
    console.log("borrado post ID ", id)

    //refersh data posts
    refreshNuxtData('posts');
}

///

</script>
